@import '@styles/_variables.scss';
.carrousel-home {
  padding: 79px Max(113px, calc((100% - 1440px) / 2 + 113px)) 113px;
  background-color: #f9f6ef;
  overflow: hidden;

  & > h1 {
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.6em;
  }

  & > div {
    display: flex;
    align-self: center;

    & > h2 {
      max-width: 590px;
      font-weight: 300;
      font-size: 80px;
      line-height: 80px;
      text-align: center;
      letter-spacing: -0.04em;

      @media screen and (max-width: $tablet-breakpoint) {
        max-width: 260px;
        font-size: 52px;
        line-height: 52px;
      }
    }
  }

  &.white {
    background-color: #fff;
  }

  &.no-margin {
    & > .row {
      margin-bottom: 0;
    }
  }

  & > .ab-paragraph-text {
    align-items: center;

    p {
      color: #8c8181;
      width: 100%;
      max-width: 590px;
      margin-top: 36px;
      text-align: center;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px !important;
      font-family: GT Eesti;
      font-style: normal;
      letter-spacing: 0.01em;

      @media screen and (max-width: 1020px) {
        font-size: 14px !important;
        line-height: 24px !important;
      }

      &.long {
        max-width: 590px;
      }

      @media screen and (max-width: 1020px) {
        margin-bottom: 11px;
        width: 100%;
      }
    }
  }

  & > #circle {
    opacity: 0;
    position: absolute;
    z-index: 9;
    display: flex;
    width: 70px;
    height: 70px;
    pointer-events: none;
    transition: opacity 1000ms ease-in-out;
  }

  & > .row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    order: 0;
    margin-bottom: 0px;
    margin-top: 70px;
    transition: all 1000ms ease-in-out;
    overflow-x: scroll;
    overflow-y: hidden;
    cursor: pointer;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-overflow-style: none;

    &.active {
      & > .card {
        transform: scale(0.95);
        user-select: none;
        -webkit-user-drag: none;
      }
    }

    &.prevent-clic {
      & > .card {
        user-select: none;
        -webkit-user-drag: none;
        pointer-events: none;
      }
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
      width: 0;
      height: 0;
    }

    &::-moz-scrollbar {
      -moz-appearance: none;
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-scrollbar {
      display: none;
    }

    @media screen and (max-width: 1020px) {
      flex-direction: row;
      margin-bottom: 32px;
      overflow-x: auto;
      height: 400px;
      margin-left: -30px;
      margin-right: -30px;
      padding-right: 30px;
      padding-left: 30px;
      margin-top: 30px;
    }

    & > :first-child {
      margin-left: 0px !important;
    }

    & > :last-child {
      margin-right: 0px !important;
    }

    & > .card {
      pointer-events: initial;
      cursor: pointer;
      flex: 1;
      display: flex;
      flex-shrink: 0;
      height: fit-content;
      width: 100%;
      min-width: 250px;
      max-width: 340px;
      min-height: 384px;
      transition: all 500ms ease-in-out;
      margin-right: 25px;
      position: relative;
      user-select: none;
      -webkit-user-drag: none;

      &:hover {
        & > :first-child {
          opacity: 0;
        }

        & > :nth-child(2) {
          opacity: 1;
          display: flex;
        }

        & > .arrow {
          background-color: #f9f6ef37;
        }
      }

      @media screen and (max-width: 1020px) {
        width: 291px;
        min-width: 291px;
        height: 381px;
        min-height: 381px;
        overflow: hidden;

        & > img {
          object-fit: cover;
        }

        & > video {
          object-fit: cover;
        }
      }

      & > :nth-child(2) {
        opacity: 0;
      }

      & > :first-child {
        display: flex;
        opacity: 1;
        position: absolute;
        transition: all 1000ms ease-in-out;
        width: 100%;
        height: 384px;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }

      & > video {
        position: absolute;
        transition: all 1000ms ease-in-out;
        pointer-events: none;
        object-fit: cover;
        user-select: none;
        width: 100%;
        height: 384px;
        opacity: 1;
        background-size: cover;

        object-fit: cover;
        align-self: center;

        &.mobile {
          display: none;
        }

        @media screen and (max-width: $tablet-breakpoint) {
          &.web {
            display: none;
          }

          &.mobile {
            display: flex;
            width: 100%;
          }
        }
      }

      & > .card-title {
        position: absolute;
        color: #fff;
        font-weight: 300;
        font-size: 30px;
        line-height: 30px;
        font-family: Silk;
        left: 25px;
        bottom: 80px;
      }

      & > .card-description {
        position: absolute;
        justify-content: center;
        display: flex;
        height: 36px;
        color: #fff;
        font-size: 12px;
        font-family: GT Eesti;
        line-height: 15px;
        max-width: 150px;
        left: 25px;
        bottom: 30px;
      }

      & > .arrow {
        position: absolute;
        color: #fff;
        right: 20px;
        width: 15px;
        height: 15px;
        max-width: 30px;
        max-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 25px;
        padding: 12px;
        border-radius: 35px;
        transition: background 1000ms ease-in-out;

        // & > img {
        //   height: 12px;
        //   width: 15px;
        // }
      }
    }

    &.no-margin {
      margin-bottom: 0;
    }
  }

  & > .gallery {
    align-self: center;
    order: 2;
  }

  & > .duplicate {
    display: none !important;
    order: 3;
  }

  &.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 120px 10px 120px Max(113px, calc((100% - 1440px) / 2 + 113px));

    @media screen and (max-width: 1020px) {
      flex-direction: column;
    }

    @media screen and (max-width: $tablet-breakpoint) {
      padding: 61px 30px 50px;
    }

    & > :first-child {
      max-width: 380px;
      margin-right: 25px;
    }

    & > div {
      & > h1 {
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        text-align: left;
        letter-spacing: 0.6em;
        text-transform: uppercase;
      }

      & > .ab-paragraph-text {
        align-items: flex-start;
        margin-top: 40px;
      }

      & > div {
        & > h2 {
          font-weight: 300;
          font-size: 60px;
          line-height: 60px;
          text-align: left;
          letter-spacing: -0.04em;

          @media screen and (max-width: $tablet-breakpoint) {
            text-align: left;
            font-size: 52px;
            line-height: 52px;
          }
        }
      }
    }

    & > .row {
      // max-width: 700px;
      height: 400px;
      max-height: 400px;
      margin-top: 0px;
      margin-left: 75px;

      transition: all 1000ms ease-in-out;
      overflow-x: scroll;
      overflow-y: hidden;
      cursor: pointer;
      -ms-overflow-style: none;

      &.active {
        & > .card {
          transform: scale(0.95);
          user-select: none;
          -webkit-user-drag: none;
        }
      }

      &.prevent-clic {
        & > .card {
          user-select: none;
          -webkit-user-drag: none;
          pointer-events: none;
        }
      }

      @media screen and (max-width: $tablet-breakpoint) {
        overflow: auto;
        margin-top: 25px;
        margin-left: 0px;
      }

      & > .card {
        min-width: 291px;
      }
    }
  }

  @media screen and (max-width: $tablet-breakpoint) {
    padding: 61px 30px 50px;
  }
}
