@import '@styles/_variables.scss';
.ab-paragraph-text {
  flex: 2;

  flex-wrap: wrap;
  align-items: flex-end;

  @media screen and (max-width: 1020px) {
    flex: initial;
    flex-wrap: nowrap;
    // height: 72px;
    align-items: flex-start;

    &.full {
      height: fit-content;

      & > p {
        height: fit-content;
        margin-bottom: 10px;
        display: initial;

        &:nth-of-type(1) {
          &:after {
            content: "";
            cursor: initial;
          }
        }

        &:last-of-type {
          &:not(.no-plus) {
            &:after {
              content: url(/images/more.svg);
              height: 28px;
              width: 28px;
              position: absolute;
              bottom: 0;
              margin-left: 5px;
              cursor: pointer;
            }
          }
          &#less {
            &:after {
              content: url(/images/less.png);
              height: 28px;
              width: 28px;
              max-height: 28px;
              max-width: 28px;
              position: absolute;
              bottom: -22px;
              margin-left: 5px;
              cursor: pointer;
              right: 0;
            }
          }
        }
        & > .estrella-michelin {
          width: 33%;
          align-items: center;
        }
      }

      & > .inner-list {
        display: initial;

      }
    }
  }

  @media screen and (max-width: $tablet-breakpoint) {
    column-count: initial !important;
    display: flex !important;
    align-items: flex-start;
  }
  & > .inner-list {
    @media screen and (max-width: $tablet-breakpoint) {
      display: none;
      &:nth-of-type(1) {
        display: initial;
      }
      &.show {
        display: initial;
      }
    }
  }
  &  tr{
    display: flex;
    flex-direction: row;
    gap: 19px;
  }
  & td {
    display:  flex;
    flex-direction: column;
    justify-content: space-between;
    & >* {
      margin: 4px auto;
    }
    & > span {
       font-size: 14px;
       min-height: 38px;

    }
    color: #8c8181;
    
  }
  & > p {
    color: #8c8181;
    // padding-left: 36px;
    width: 100%;
    max-width: 381px;
    position: relative;
    transition: opacity 500ms;
    margin-bottom: 10px;
    box-sizing: border-box;
    text-align: left;
    @media screen and (max-width: $tablet-breakpoint) {
      // height: 144px;
      width: 100%;
      max-width: initial;
      padding-left: 0;
      display: none;

      text-overflow: ellipsis;

      &:nth-of-type(1) {
        display: initial;
        &:not(.no-plus) {
          &:after {
            content: url(/images/more.svg);
            height: 28px;
            width: 28px;
            position: absolute;
            bottom: -2px;
            margin-left: 5px;
            cursor: pointer;
            right: 0;
          }
        }
        &#less {
          &:after {
            content: url(/images/less.png);
            height: 28px;
            width: 28px;
            max-height: 28px;
            max-width: 28px;
            position: absolute;
            bottom: -22px;
            margin-left: 5px;
            cursor: pointer;
            right: 0;
          }
        }
      }
      &.show {
        display: initial;
      }
    }

    & > table {
      width: 100%;
      & > tbody {
        & > tr {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: baseline !important;
          & > td {
            padding: 5px;
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            & > span {
              text-align: center;
            }
          }
        }
      }
    }
  }

  & > button {
    display: none;

    @media screen and (max-width: 1020px) {
      display: inline;
    }
  }

  @media screen and (max-width: 1020px) {
    width: 100%;

    &:nth-of-type(2) {
      display: none;
    }
  }
}
